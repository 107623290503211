import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // Button,
  Typography,
  Link,
} from "@mui/material";
import { Form, Input, Button } from "antd";
import { IoMdRefreshCircle } from "react-icons/io";
import OtpInput from "otp-input-react";
import axios from "axios";
import styles from "./MsgModal.css";

const MobileNumberAlert = ({
  open,
  onClose,
  openClaim,
  setOpenClaim,
  setResponse,
  selectedEvent,
}) => {
  const [form] = Form.useForm();
  const [otp, setOtp] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [verifyotp, setVerifyOtp] = useState("");
  const [otploader, setOtploader] = useState(false);
  // const [openClaim, setOpenClaim] = useState(false);

  const onchangeOtp = (e) => {
    setOtp(e.target.value);
  };
  const handleClaim = () => {
    setOpenClaim(true);
    // API call to claim the gate pass
    // onClose();
  };
  const onFinishFailed = (errorInfo) => {
    // setLoader(false);
    // toast.error("Something went wrong !");
    alert("Fill the required fields");
    console.log("Failed:", errorInfo);
  };
  const onFinish = async (values) => {
    setOtploader(true);
    try {
      const response = await axios.post("/api/again/download", {
        number: values.mobile,
        selectedEvent,
      });
      // console.log(response);
      // return

      // Handle response
      if (response.data.status === "success") {
        alert(
          "Mobile number verified successfully. You can download your gate pass using the button below"
        );
        // setUser(response.data);
        setOtploader(false);
        setShowOTP(false);
        setVerifyOtp("");
        // setPh("");
        setOtp("");
        setResponse(response.data);
        onClose();
        form.resetFields();
      }
    } catch (error) {
      // Handle error
      if (error.status === 404) {
        alert(error.response.data.message);
      } else {
        alert("Something went wrong. Please try again later.");
      }
      setOtploader(false);
      console.error("Error:", error);
    }
  };

  const onClickOtpLoading = async () => {
    try {
      await form.validateFields();
      setOtploader(true);

      const response = await axios.post("/api/send/otp/", {
        number: otp,
        selectedEvent,
      });
      // Handle response
      if (response.data.type === "success") {
        alert("OTP sent successfully.");
        setOtploader(false);
        setShowOTP(true);
      }
    } catch (error) {
      // Handle error
      alert(
        error?.response?.data?.message ||
        "Something went wrong. Please try again."
      );
      console.error("Error:", error);
      setOtploader(false);
    }
  };

  async function onOTPVerify() {
    if (!verifyotp) {
      return alert("Enter OTP for verification");
    }
    setOtploader(true);
    try {
      const response = await axios.post("/api/verify/otp/", {
        otp: verifyotp,
        number: otp,
        selectedEvent,
      });
      // console.log(response);
      // return

      // Handle response
      if (response.data.data.type === "success") {
        alert(
          "Mobile Number Verified successfully. you can download your gatepass form the below"
        );
        // setUser(response.data);
        setOtploader(false);
        setShowOTP(false);
        setVerifyOtp("");
        // setPh("");
        setOtp("");
        setResponse(response.data.user);
        onClose();
        form.resetFields();
      } else {
        if (response.data.type === "error") {
          alert(response.data.message);
        }
      }
      setOtploader(false);
    } catch (error) {
      // Handle error
      setOtploader(false);
      console.error("Error:", error);
    }
  }

  const handleResend = async () => {
    if (otp) {
      const response = await axios.post("/api/resend", { number: otp });
      // console.log(response);

      if (response.data.type === "success") {
        alert("Resend OTP Successfully");
      }
    } else {
      alert("Something went wrong. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Mobile Number Already Registered</DialogTitle>
      <DialogContent>
        {/* <Typography variant="body1" gutterBottom>
          This mobile number is already in use. You can download your gate pass
          using the link below.
        </Typography> */}
        <Typography variant="body1" gutterBottom>
          {/* You have already downloaded the gate pass for this event. To download
          it again, click the button below.{" "} */}
          Mobile Number Already Registered for this Event! To access your entry
          pass again, please click the link below.
          <Link onClick={handleClaim} style={{ cursor: "pointer" }}>
            Regenerate Pass
          </Link>
        </Typography>

        {/* <Typography variant="h6" gutterBottom>
          <Link
            href="https://example.com/download-gate-pass"
            onClick={handleDownloadClick}
            underline="hover"
          >
            Download Gate Pass
          </Link>
        </Typography> */}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClaim} type="primary">
          Gate Pass Claim
        </Button> */}
        <Button onClick={onClose} type="primary">
          Ok
        </Button>
      </DialogActions>
      {openClaim && (
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          labelWrap
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Typography variant="body1" style={{ textAlign: "center" }}>
            Regenerate Your Gate Pass
          </Typography>
          <Form.Item
            style={{ margin: "15px" }}
            label="Enter Registered Mobile Number"
            name="mobile"
            rules={[
              {
                required: true,
                message: "Please input Mobile Number!",
              },
              // {
              //   len: 10,
              //   message: "Mobile Number must be exactly 10 digits!",
              // },
              {
                pattern: /^[6-9]\d{9}$/,
                message:
                  "Mobile Number must be a valid Indian number starting with 6-9 and 10 digits!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Enter Candidate Mobile Number"
              onChange={onchangeOtp}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "15px", textAlign: "center" }}
            className={styles.addProductBtnContainer}
          >
            <Button
              type="primary"
              htmlType="submit"
              // shape="round"
              loading={otploader}
            // onClick={onClickOtpLoading}
            // style={{ backgroundColor: "#2c3e50" }}
            >
              Submit
            </Button>
          </Form.Item>

          {/* {showOTP && (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h>Enter OTP</h> &nbsp;&nbsp;
                <OtpInput
                  value={verifyotp}
                  onChange={setVerifyOtp}
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container "
                ></OtpInput>
                <b>
                  <a type="button" onClick={handleResend} class="text-danger">
                    Resend OTP
                  </a>
                </b>
              </div>
              <Form.Item
                style={{ margin: "15px", textAlign: "center" }}
                className={styles.addProductBtnContainer}
              >
                <Button
                  type="primary"
                  // htmlType="submit"
                  shape="round"
                  loading={otploader}
                  onClick={onOTPVerify}
                  style={{ backgroundColor: "#2c3e50" }}
                >
                  Verify OTP
                </Button>
              </Form.Item>
            </>
          )}
          {otp && !showOTP && (
            <Form.Item
              style={{ marginBottom: "15px", textAlign: "center" }}
              className={styles.addProductBtnContainer}
            >
              <Button
                type="primary"
                // htmlType="submit"
                shape="round"
                loading={otploader}
                onClick={onClickOtpLoading}
                style={{ backgroundColor: "#2c3e50" }}
              >
                Send OTP
              </Button>
            </Form.Item>
          )} */}
        </Form>
      )}
    </Dialog>
  );
};

export default MobileNumberAlert;
