import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AdminLayout from "./Drawer";
import { formatDate, formatDateTime } from "../comman";

const Redownload = () => {
  const [events, setEvents] = useState([]);
  const [list, setList] = useState([]);
  useEffect(() => {
    fetchEvents();
    fetchUsers();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch("/api/get/events", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      //    console.log(data);

      if (data.status === "success") {
        setList(data.data);
      } else {
        console.error("Error in response data:", data);
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await fetch("/api/get/redownloads", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      //   console.log(data);

      if (data.status === "success") {
        setEvents(data.data);
      } else {
        console.error("Error in response data:", data);
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };

  // console.log(schemes)
  const columns = [
    { field: "id", headerName: "Sr.No.", minWidth: 40 },

    // PPP Grouping (Total, Pending, Approved, Rejected)
    {
      field: "name",
      headerName: "User Name",
      minWidth: 150,
      flex: 1,
      cellClassName: "total-column",
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      minWidth: 150,
      flex: 0.7,
      cellClassName: "pending-column",
    },
    // { field: "code", headerName: "Code", minWidth: 150, flex: 1, cellClassName: 'approved-column' },
    {
      field: "pincode",
      headerName: "Pincode",
      minWidth: 100,
      flex: 0.5,
      cellClassName: "rejected-column",
    },

    // DPP Grouping (Total, Pending, Approved, Rejected)
    {
      field: "address",
      headerName: "Address",
      minWidth: 150,
      flex: 1,
      cellClassName: "total-column",
    },
    {
      field: "event",
      headerName: "Event Name",
      minWidth: 150,
      flex: 1,
      cellClassName: "approved-column",
    },
    {
      field: "creation",
      headerName: "Creation Date",
      minWidth: 150,
      flex: 1,
      cellClassName: "approved-column",
    },
  ];
  //   console.log(list);

  const handleStatus = (value) => {
    const event = list && list?.filter((item) => item._id === value)[0];
    return event?.eventName;
  };
  const rows =
    events &&
    events?.map((item, index) => ({
      id: index + 1,
      name: item?.firstName,
      mobileNumber: item?.mobileNumber,
      pincode: item?.pincode,
      address: item.address,
      limit: item.eventLimit,
      event: handleStatus(item.eventId),
      creation: item?.createdAt && formatDateTime(item.createdAt),
    }));

  return (
    <AdminLayout>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        autoHeight
        slots={{
          toolbar: GridToolbar,
          NoRowsOverlay: <>No data found</>,
        }}
        classes={{
          root: "table-background",
          // columnHeaders: 'header-background'
        }}
      />
    </AdminLayout>
  );
};

export default Redownload;
