import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AdminLayout from "./Drawer";
import { formatDate } from "../comman";
import { Button } from "@material-ui/core";
import UpdateModal from "./Modal";
import { Form } from "antd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  // minHeight: "70%",
  maxHeight: "90vh", // Set maximum height to 90% of the viewport height
  overflowY: "auto", // Enable vertical scrolling if content exceeds the height
  bgcolor: "background.paper",
  border: "2px #000",
  boxShadow: 24,
  p: 4,
  // borderRadius: 4
};
const DataTable = () => {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  // console.log(data)
  // const schemes = Array.from(new Set(data?.map(item => item.scheme)));
  // const categories = ['ppp', 'dpp', 'demand'];

  const handleClick = (item) => {
    setOpen(true);
    if (item) {
      setData(item);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setData(null);
    form.resetFields();
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch("/api/get/events", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);

      if (data.status === "success") {
        setEvents(data.data);
      } else {
        console.error("Error in response data:", data);
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };
  const onFinish = async (values) => {
    // console.log(values);
    const payload = {
      eventName: values.eventName,
      eventDate: values.eventDate,
      code: values.code,
      registrationCount: values.registrationCount,
      eventLimit: values.eventLimit,
      startDate: values.startDate,
      endDate: values.endDate,
      active: values.active,
      eventId: data && data?._id,
    };

    try {
      // const uri = data ? "/api/update/event" : "/api/make/event";
      //for developemt environments
      const response = await fetch("/api/update/event", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Update Successful");
        fetchEvents();
        handleClose();

        // console.log("Registration successful:", data);
      } else {
        console.error("Registration failed:", data);
        alert(`${data?.message || "Something went wrong"}`);
      }
    } catch (error) {
      console.error("Error while registering:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  const onFinishFailed = () => {};

  // console.log(schemes)
  const columns = [
    { field: "id", headerName: "Sr.No.", minWidth: 60 },

    // PPP Grouping (Total, Pending, Approved, Rejected)
    {
      field: "eventName",
      headerName: "Event Name",
      minWidth: 150,
      flex: 1,
      cellClassName: "total-column",
    },
    {
      field: "eventDate",
      headerName: "Event Date",
      minWidth: 150,
      flex: 1,
      cellClassName: "pending-column",
    },
    // { field: "code", headerName: "Code", minWidth: 150, flex: 1, cellClassName: 'approved-column' },
    {
      field: "count",
      headerName: "Registration Count",
      minWidth: 150,
      flex: 1,
      cellClassName: "rejected-column",
    },

    // DPP Grouping (Total, Pending, Approved, Rejected)
    {
      field: "limit",
      headerName: "Event Limit",
      minWidth: 150,
      flex: 1,
      cellClassName: "total-column",
    },
    {
      field: "start",
      headerName: "Start Date",
      minWidth: 150,
      flex: 1,
      cellClassName: "approved-column",
    },
    {
      field: "end",
      headerName: "End Date",
      minWidth: 150,
      flex: 1,
      cellClassName: "approved-column",
    },

    {
      field: "active",
      headerName: "Active",
      minWidth: 150,
      flex: 1,
      cellClassName: "total-column",
      renderCell: (params) => (
        <span
          style={{
            color: params.value === "Active" ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Action",
      minWidth: 110,
      flex: 1,
      headerClassName: "custom-header",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleClick(params.row.status)}
        >
          Edit
        </Button>
      ),
    },
  ];
  const handleStatus = (item) => {
    return item ? "Active" : "Inactive";
  };
  const rows =
    events &&
    events?.map((item, index) => ({
      id: index + 1,
      eventName: item?.eventName,
      eventDate: item?.eventDate && formatDate(item.eventDate),
      code: item?.code,
      count: item.registrationCount,
      limit: item.eventLimit,
      active: handleStatus(item.active),
      status: item,
      start: item?.startDate && formatDate(item.startDate),
      end: item?.endDate && formatDate(item.endDate),
    }));

  return (
    <AdminLayout>
      {/* <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mb: 2, // Optional: Adds some margin below the button
                }}
            >
                <Button
                    variant="contained"
                    color="primary" // Use MUI's default blue color
                    size="small"
                    onClick={() => handleClick()}
                >
                    Create Event
                </Button>
            </Box> */}

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        autoHeight
        hideFooter={true}
        components={{
          // Toolbar: GridToolbar,
          NoRowsOverlay: <>No data found</>,
        }}
        classes={{
          root: "table-background",
          // columnHeaders: 'header-background'
        }}
      />
      <UpdateModal
        style={style}
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        edit={data}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
    </AdminLayout>
  );
};

export default DataTable;
