export function formatDateToDDMMM(dateString) {
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];


  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];

  return `${month}-${day}`;
}

export const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(d.getFullYear()); // Get the last two digits of the year
  // const hours = String(d.getHours()).padStart(2, '0');
  // const minutes = String(d.getMinutes()).padStart(2, '0');
  // const seconds = String(d.getSeconds()).padStart(2, '0');
  return `${day}-${month}-${year}`;
  // ${hours}:${minutes}:${seconds}`;
};

export const formatDateTime = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(d.getFullYear()); // Get the last two digits of the year
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  const seconds = String(d.getSeconds()).padStart(2, '0');
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};
