import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Success Icon

const RegistrationModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px", // Rounded corners for the modal
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)", // Soft shadow for depth
        },
      }}
    >
      {/* Modal Title */}
      <DialogTitle
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#FF7F32", // Saffron color for the title
          textAlign: "center",
        }}
      >
        Registration Successful
      </DialogTitle>

      {/* Modal Content with Thumbnail and Text */}
      <DialogContent
        sx={{
          fontFamily: "Poppins, sans-serif",
          color: "#333",
          fontSize: "16px",
          textAlign: "center",
          padding: "30px",
        }}
      >
        {/* Success Icon with Motion Effect */}
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "20px",
            animation: "bounce 1s ease infinite", // Bounce animation for motion effect
          }}
        >
          <CheckCircleIcon
            sx={{
              fontSize: "80px",
              color: "#4caf50", // Green color for success
              animation: "scaleUp 1s ease-in-out infinite", // Scale-up animation effect
            }}
          />
        </Box>

        {/* Success message */}
        <Typography
          variant="body1"
          sx={{ marginBottom: "15px", fontWeight: "bold" }}
        >
          Your registration for the event has been successfully completed!
          You can download your gate pass by clicking the button below.
        </Typography>
        {/* <Typography
          variant="body2"
          sx={{ marginBottom: "25px", color: "#555", lineHeight: "1.6" }}
        >
          You can download your gate pass by clicking the button below.
          Alternatively, you can download the gate pass by{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.bharatonline.customer.app"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#007BFF" }}
          >
            clicking on the link
          </a>
          .
        </Typography> */}
      </DialogContent>

      {/* Modal Actions with Close Button */}
      <DialogActions
        sx={{
          justifyContent: "center", // Centering the button
          padding: "20px",
        }}
      >
        {/* Close Button */}
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: "#FF7F32", // Saffron color
            color: "white",
            fontWeight: "bold",
            borderRadius: "20px",
            textTransform: "uppercase",
            padding: "10px 20px",
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: "#FF5722", // Darker saffron on hover
            },
          }}
        >
          Close
        </Button>
      </DialogActions>

      {/* CSS for Animations */}
      <style jsx>{`
        /* Define Bounce and Scale-Up Animations */
        @keyframes bounce {
          0%,
          100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }

        @keyframes scaleUp {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
    </Dialog>
  );
};

export default RegistrationModal;
