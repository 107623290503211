import html2canvas from "html2canvas";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatDateTime } from "./Date";
import { Grid } from "@material-ui/core";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import html2pdf from "html2pdf.js";
import { IoMdDownload } from "react-icons/io";

const InvitationCard = ({ event, download, view, modalCloseRef }) => {
  // console.log(event);
  // return

  const ref = React.useRef(null);
  const [showPasss, setShowPass] = useState(false);
  const [showQrButtons, setShowQrButtons] = React.useState(false);
  const submitButtonRef = useRef(null);
  const [qr, setQr] = useState("");

  const GenerateQRCode = () => {
    QRCode.toDataURL(
      event?.data?.mobileNumber,
      {
        width: 240,
        margin: 1,
        // color: {
        // 	dark: '#335383FF',
        // 	light: '#EEEEEEFF'
        // }
      },
      (err, url) => {
        if (err) return console.error(err);

        console.log(url);
        setQr(url);
      }
    );
  };
  useEffect(() => {
    GenerateQRCode();
  }, []);
  const containerStyle = {
    maxWidth: "380px",
    backgroundColor: "#f4f4f4",
    margin: "0 auto", // Center horizontally
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    // height: "100vh",
  };

  const eventDetails = {
    "6740d076e25eb4fdeec3e3fb": {
      date: "शुक्रवार १३ डिसेंबर २०२४",
      image: "/0.png",
      name: "मैं हू भारत",
      download: "13_Dec_Gate_pass.pdf",
    },
    "6740d090e25eb4fdeec3e3fd": {
      date: "शनिवार १४ डिसेंबर २०२४",
      image: "/1.png",
      // image: "/Dec14.jpg",
      name: "अपने अपने राम",
      download: "14_Dec_Gate_pass.pdf",
    },
    "6740d09ce25eb4fdeec3e3ff": {
      date: "रविवार १५ डिसेंबर २०२४",
      // image: "/Dec15.jpg",
      image: "/1.png",
      name: "अपने अपने राम",
      download: "15_Dec_Gate_pass.pdf",
    },
    "6740d0a7e25eb4fdeec3e401": {
      date: "सोमवार १६ डिसेंबर २०२४",
      // image: "/Dec16.jpg",
      image: "/1.png",
      name: "अपने अपने राम",
      download: "16_Dec_Gate_pass.pdf",
    },
    "6740d0cce25eb4fdeec3e403": {
      date: "मंगळवार १७ डिसेंबर २०२४",
      // image: "/Dec17.jpg",
      image: "/2.png",
      name: "अभंग वारी",
      download: "17_Dec_Gate_pass.pdf",
    },
    "6740d0e1e25eb4fdeec3e405": {
      date: "बुधवार १८ डिसेंबर २०२४",
      // image: "/Dec18.jpg",
      image: "/3.png",
      name: "अभिजात मराठी",
      download: "18_Dec_Gate_pass.pdf",
    },
    "6740d102e25eb4fdeec3e407": {
      date: "गुरुवार १९ डिसेंबर २०२४",
      // image: "/Dec19.jpg",
      image: "/4.png",
      name: "फ्युजन",
      download: "19_Dec_Gate_pass.pdf",
    },
    "6740d117e25eb4fdeec3e409": {
      date: "शुक्रवार २० डिसेंबर २०२४",
      // image: "/Dec20.jpg",
      image: "/5.png",
      name: "सनम बँड लाईव",
      download: "20_Dec_Gate_pass.pdf",
    },
    "6740d12de25eb4fdeec3e40b": {
      date: "शनिवार २१ डिसेंबर २०२४",
      // image: "/Dec21.jpg",
      image: "/6.png",
      name: "उदीत नारायण",
      download: "21_Dec_Gate_pass.pdf",
    },
    "6740d146e25eb4fdeec3e40d": {
      date: "रविवार २२ डिसेंबर २०२४",
      // image: "/Dec22.jpg",
      image: "/7.png",
      name: "विशाल मिश्रा",
      download: "22_Dec_Gate_pass.pdf",
    },
  };
  const selectedEventDetails = eventDetails[event?.data?.eventId] || {};

  const handleDownload = () => {
    const element = ref.current;
    if (!element) {
      console.error("Ref is null");
      return;
    }

    // Calculate the height based on the aspect ratio and desired width
    const aspectRatio = element.offsetHeight / element.offsetWidth;
    const pdfHeight = Math.ceil(380 * aspectRatio);

    const options = {
      margin: 0,
      filename: selectedEventDetails.download,
      image: { type: "png", quality: 1 },
      html2canvas: {
        scale: 2, // Adjust scaling as needed
        width: 380, // Set the desired width
        height: pdfHeight, // Set the calculated height
        useCORS: true,
      },
      jsPDF: {
        unit: "px",
        format: [380, pdfHeight],
        orientation: "portrait",
      },
    };

    html2pdf().set(options).from(element).save();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleDownload();
    }, 2000); // 5000 milliseconds = 5 seconds

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, [event]);

  // const generateDataUrl = useCallback(() => {
  //   return new Promise((resolve, reject) => {
  //     if (ref.current === null) {
  //       reject(new Error("Ref is null"));
  //       return;
  //     }
  //     toPng(ref.current, { cacheBust: true, pixelRatio: 1.9 })
  //       .then((dataUrl) => resolve(dataUrl))
  //       .catch((err) => reject(err));
  //   });
  // }, [ref]);

  // const handleDownload = useCallback(async () => {
  //   try {
  //     const dataUrl = await generateDataUrl();

  //     // Set PDF dimensions to match the content size
  //     const pdfWidth = 380; // Match your maximum gate pass width in pixels
  // const pdfHeight =
  //   (ref.current.offsetHeight / ref.current.offsetWidth) * pdfWidth; // Calculate proportional height

  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "px",
  //       format: [pdfWidth, pdfHeight], // Custom format for mobile size
  //     });

  //     pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.save("gate-pass.pdf");
  //   } catch (err) {
  //     console.error("Error downloading PDF:", err);
  //   }
  // }, [generateDataUrl]);

  //deploymeny

  //PRoduction

  // const eventDetails = {
  //   "6743219da2c442c836d4a7fe": {
  //     date: "गुरुवार १३ डिसेंबर २०२४",
  //     image: "/0.png",
  //     name: "मैं हू भारत",
  //   },
  //   "67432233a2c442c836d4a800": {
  //     date: "शुक्रवार १४ डिसेंबर २०२४",
  //     image: "/1.png",
  //     // image: "/Dec14.jpg",
  //     name: "अपने अपने राम",
  //   },
  //   "6743224ea2c442c836d4a802": {
  //     date: "शनिवार १५ डिसेंबर २०२४",
  //     // image: "/Dec15.jpg",
  //     image: "/1.png",
  //     name: "अपने अपने राम",
  //   },
  //   "67432266a2c442c836d4a804": {
  //     date: "रविवार १६ डिसेंबर २०२४",
  //     image: "/1.png",
  //     name: "अपने अपने राम",
  //   },
  //   "67432288a2c442c836d4a806": {
  //     date: "सोमवार १७ डिसेंबर २०२४",
  //     image: "/2.png",
  //     name: "अभंग वारी",
  //   },
  //   "674322b2a2c442c836d4a808": {
  //     date: "मंगळवार १८ डिसेंबर २०२४",
  //     image: "/3.png",
  //     name: "अभिजात मराठी",
  //   },
  //   "674322d4a2c442c836d4a80a": {
  //     date: "बुधवार १९ डिसेंबर २०२४",
  //     image: "/4.png",
  //     name: "फ्युजन",
  //   },
  //   "674322f2a2c442c836d4a80c": {
  //     date: "गुरुवार २० डिसेंबर २०२४",
  //     image: "/5.png",
  //     name: "सनम बँड लाईव",
  //   },
  //   "67432311a2c442c836d4a80e": {
  //     date: "शुक्रवार २१ डिसेंबर २०२४",
  //     image: "/6.png",
  //     name: "उदीत नारायण",
  //   },
  //   "67432332a2c442c836d4a810": {
  //     date: "शनिवार २२ डिसेंबर २०२४",
  //     image: "/7.png",
  //     name: "विशाल मिश्रा",
  //   },
  // };
  const handleViewClick = () => {
    setShowPass(true);
    setTimeout(() => {
      submitButtonRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);
  };
  const boxLeftStyle = {
    background: "linear-gradient(180deg, #3e1d0a, #ff147a)",
    border: "1px solid #7e4104",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    borderRadius: "10px",
    width: "100%", // Make the box take full width inside the container
    maxWidth: "380px", // Set a max width for responsiveness
    // padding: "10px",
    boxSizing: "border-box", // Include padding in width/height calculation
  };

  const headerStyle = {
    fontFamily: "'Arya', sans-serif",
    color: "#ffd700",
    fontSize: "clamp(36px, 5vw, 44px)", // Responsive font size
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "20px",
    // margin: 0,
    // padding: 0,
    lineHeight: "0.8",
    // display: 'flex',
    alignItems: "center",
  };

  const detailsStyle = {
    // backgroundColor: "#e6df86",
    borderRadius: "6px",
    padding: "10px",
    textAlign: "center",
    fontSize: "clamp(14px, 3vw, 20px)", // Responsive font size
    lineHeight: "1.6",
    fontWeight: "bolder",
    color: "#1d348c",
  };
  const textStyle = {
    fontFamily: "'Arya', sans-serif",
    fontSize: "clamp(28px, 5vw, 44px)", // Values should be strings
    fontWeight: "bold",
    textAlign: "center",
    background: "linear-gradient(to bottom, #ff9933, #ffffff, #008000)",
    WebkitBackgroundClip: "text", // Use camelCase for vendor prefixes
    WebkitTextFillColor: "transparent", // Correct camelCase for -webkit-text-fill-color
    // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Shadow effect
    marginTop: "2vh",
  };
  const imageStyle = {
    position: "absolute",
    top: "10px",
    right: "4px",
    width: "80px",
    height: "auto",
    borderRadius: "8px",
  };
  const logoStyle = {
    position: "absolute",
    top: "10px",
    left: "2px",
    width: "80px",
    height: "auto",
    // borderRadius: '8px',
  };

  const guestNameStyle = {
    fontFamily: "'Arial', sans-serif",
    fontSize: "16px",
    color: "white",
    fontWeight: "normal",
    marginLeft: "10px",
    marginTop: "1vh",
  };

  const qrCodeContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    // marginTop: "10px",
    // borderTop: "2px dotted #ddd",
    borderBottom: "2px dotted #ddd",
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#f7d387", // Light tan background
    borderRadius: "10px", // Rounded corners
    padding: "10px 15px",
    border: "2px solid #f3f3f3",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow
    width: "260px", // Adjust to your needs
    height: "80px",
  };

  const mainContainerStyle = {
    // backgroundColor: "#ff147a", // Pink background
    padding: "10px",
    borderRadius: "15px", // Rounded corners for the pink card
    display: "inline-block",
  };

  const largeTextStyle1 = {
    fontSize: "60px", // Size of "2A"
    fontWeight: "bold",
    color: "#000", // Black text
    transform: "scaleY(1.8)",
  };

  const sideTextStyle = {
    fontSize: "14px", // Font size for the small text
    color: "#000", // Black text
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.2", // Adjust spacing between lines
    textAlign: "center",
    // marginRight:'20px',
    marginBottom: "10px",
  };

  const headerTextStyle = {
    color: "#ce0059", // Pink color for "प्रवेशद्वार"
    fontWeight: "bold",
    fontSize: "16px",
  };

  return (
    <>
      <div className="button-container">
        {/* <button onClick={handleViewClick} className="btn-view">
          <span className="thumbnail-icon">🔍</span> {view}
        </button> */}
        <button
          // icon={<IoMdDownload />}
          ref={modalCloseRef}
          onClick={() => {
            setShowPass(true);
            handleDownload();
            // setShowForm(false);
          }}
          // onClick={handleDownload}
          className="btn-view"
        >
          <IoMdDownload />
          {download}
        </button>
      </div>

      <style jsx>{`
        .button-container {
          display: flex;
          justify-content: center;
          gap: 15px;
          margin: 20px;
        }

        .btn-download,
        .btn-view,
        .btn-views {
          padding: 12px 20px;
          font-size: 20px;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: background-color 0.3s ease, transform 0.2s ease-in-out;
          font-weight: 600;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .btn-download {
          background: linear-gradient(
            135deg,
            #ff7f32,
            #03045e
          ); /* Mixed gradient color */
          color: white;
        }

        .btn-view {
          background: linear-gradient(
            135deg,
            #4c6ef5,
            #2a4f9c
          ); /* Stronger blue gradient */
          color: white;
          position: relative;
        }

        .btn-views {
          background: linear-gradient(
            135deg,
            #f39c12,
            #d35400
          ); /* Golden Yellow to Dark Goldenrod */
          color: white;
          position: relative;
        }

        .thumbnail-icon {
          font-size: 18px;
          margin-right: 8px;
        }

        /* Hover effects */
        .btn-download:hover {
          background: linear-gradient(135deg, #f3a6d7, #ff7f32);
          transform: scale(1.05);
        }

        .btn-view:hover {
          background: linear-gradient(135deg, #7da4c3, #a3c7e1);
          transform: scale(1.05);
        }

        /* Focus and active states */
        .btn-download:focus,
        .btn-view:focus {
          outline: none;
          box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
        }

        .btn-download:active,
        .btn-view:active {
          transform: scale(0.98);
        }
      `}</style>

      {/* {showPasss && ( */}
      <div style={containerStyle}>
        <div style={boxLeftStyle} ref={ref}>
          <div>
            <div style={headerStyle}>
              <span style={{ margin: 0, padding: 0 }}>प्रवेशिका</span>
              <br />
              <span
                style={{
                  color: "white",
                  fontSize: "20px",
                  margin: 0,
                  padding: 0,
                  lineHeight: "1",
                }}
              >
                निमंत्रित
              </span>
            </div>
            <div style={textStyle}>"{selectedEventDetails.name}"</div>
            <img
              src={selectedEventDetails.image}
              alt="Image"
              style={imageStyle}
              crossOrigin="anonymous"
            />

            <img
              src="/image.png"
              alt="khasdar"
              style={logoStyle}
              crossOrigin="anonymous"
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={guestNameStyle}>
                <span>श्री./सौ. {event?.data?.firstName}</span>
                <br />
                <span>मोबाईल क्र. {event?.data?.mobileNumber}</span>
              </div>
            </div>

            {/* <div style={guestNameStyle}>
                <span>श्री./सौ. {event?.data?.firstName}</span>
                <br />
                <span>मोबाईल क्र. {event?.data?.mobileNumber}</span>
              </div> */}
            <div style={detailsStyle}>
              <span
                style={{
                  color: "orange",
                  fontSize: "clamp(20px, 4vw, 28px)",
                  fontWeight: "bold",
                }}
              >
                {selectedEventDetails.date || "तारीख उपलब्ध नाही"} सायं.६ वाजता
              </span>

              <div style={qrCodeContainerStyle}>
                {/* <img
                  src={event?.data?.qrCodeLink}
                  alt="QR Code"
                  style={qrCodeStyle}
                /> */}
                <img src={qr} crossOrigin="anonymous" />
              </div>
              <div style={mainContainerStyle}>
                <div style={cardStyle}>
                  <span style={largeTextStyle1}>2A</span>
                  <div style={sideTextStyle}>
                    <span style={headerTextStyle}>
                      प्रवेशद्वार प्रगती हॉल समोर <br /> हनुमान नगर
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        lineheight: "1.0",
                        marginTop: "4px",
                        color: "#3e1d0a",
                      }}
                    >
                      स्थळ: ईश्वर देशमुख शारीरिक शिक्षण महाविद्यालय, क्रीडा चौक,
                      हनुमान नगर, नागपूर.
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    color: "black",
                    fontWeight: "normal",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Powered By
                  <img
                    src="/bharatOnline.png"
                    alt="bharatOnline"
                    crossOrigin="anonymous"
                    style={{
                      marginRight: "8px",
                      height: "20px",
                      marginLeft: "6px",
                    }} // Adjust the size as needed
                  />
                  Bharat Online
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default InvitationCard;
