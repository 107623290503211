import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  Button,
  CardHeader,
  Select,
  MenuItem,
  Typography,
  ThemeProvider,
  createTheme,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import Receipt from "./receipt";
import { useParams } from "react-router-dom";
import RegistrationModal from "./modal";
import { formatDateToDDMMM } from "./comman";
import { CiLocationOn } from "react-icons/ci";
import { QRCodeCanvas } from "qrcode.react";
import Marquee from "./Marquee";
import "./App.css";
import MobileNumberAlert from "./MsgModal";
import InvitationCard from "./InvitationCard";
import LimitMsgModal from "./LimitMsgModal";

// Styles
const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: "300px", // Default for web
    height: "auto",
    margin: "0 auto",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "200px", // Smaller for mobile
    },
  },
  languageSelect: {
    margin: theme.spacing(2, 0),
    minWidth: "50px",
  },
  button: {
    margin: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(3),
    // backgroundColor: "#fffcde", // Faint yellow background
  },
}));

// Validation Schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name should only contain letters")
    .required("Name is required"),

  // lastName: Yup.string().required("Last Name is required"),

  mobileNumber: Yup.string()
    .matches(
      /^[6-9]\d{9}$/,
      "Mobile Number must be a valid Indian number starting with 6-9 and 10 digits"
    )
    .required("Mobile Number is required"),
  // email: Yup.string().email("Invalid email format").required("Email is required")
});

const UserForm = () => {
  const params = useParams();
  const { id } = params;
  const classes = useStyles();
  const submitButtonRef = useRef(null);
  const modalCloseRef = useRef(null);

  const [selectedLanguage, setSelectedLanguage] = React.useState("English");
  const [response, setResponse] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [megaEvents, setMegaEvents] = useState();
  const [limitMsgOpen, setLimitMsgOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [location, setLocation] = useState(false);
  const [openClaim, setOpenClaim] = useState(false);
  const [limitModalMsgOpen , setLimitModalMsgOpen] = useState(false)
  // console.log(megaEvents);
  const today = new Date();
  // console.log(response);

  // Function to toggle modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLimitClose = () => {
    setLimitMsgOpen(false);
    setErrorMsg(null);
    setLocation(false);
   
  };
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkLimit = events.filter((event) => event.code === id)[0];
  //  console.log(checkLimit);
  // console.log(process.env.REACT_APP_MAPAPIKEY);

  const handleModalClose = () => {
    setModalOpen(false);
    setOpenClaim(false);
     setLimitModalMsgOpen(false);
  };
  // Extract first two words of the address
  const getShortAddress = (address) => {
    const words = address.split(" ");
    return words.slice(0, 3).join(" ") + (words.length > 2 ? "..." : "");
  };
  // console.log(address)
  // console.log(pincode)
  const fetchAddress = async (latitude, longitude) => {
    // console.log("latitude ::", latitude, "longitude ::", longitude);
    try {
      // const response = await fetch(
      //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAPAPIKEY}`
      // );
      const response = await fetch(
        `/api/geocode?lat=${latitude}&lng=${longitude}`
      );

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }
      const data = await response.json();
      // console.log("data: " + data);

      if (data.results && data.results.length > 0) {
        // Set the formatted address
        setAddress(data.results[0].formatted_address);

        // Look for the pincode in address_components
        let pincodeFound = false; // Track if pincode is found
        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes("postal_code")) {
              setPincode(component.long_name); // Extract and set the pincode
              pincodeFound = true;
              break; // Exit the inner loop
            }
          }
          if (pincodeFound) break; // Exit the outer loop if pincode is found
        }
      }
    } catch (err) {
      setError("Error fetching address");
      console.error("Error fetching address:", err);
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLat(latitude);
          setLong(longitude);
          fetchAddress(latitude, longitude);
        },
        (err) => {
          setError("Unable to retrieve your location");
          console.error("Error getting location:", err);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      console.error("Geolocation is not supported by this browser.");
    }
  }, [location]);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleImageClick = (eventValue, name) => {
    const checkLimit = events.filter((event) => event.code === eventValue);
    if (checkLimit[0].registrationCount >= checkLimit[0].eventLimit) {
      // setErrorMsg(
      //   "Current entry pass slots are filled. Check back soon for more availability!"
      // );
      // setLimitMsgOpen(true);
      setSelectedEvent(eventValue);
      setLimitModalMsgOpen(true);
      return;

      // alert(
      //   "Entry passes are fully booked; the limit has been crossed."
      // );
    }
    if (!checkLimit[0].active) {
      setLimitMsgOpen(true);
      return setErrorMsg(
        "This Event is No Longer Active! Thank you for your interest. Stay tuned for upcoming events."
      );
      // return alert("This event is not active. Please choose another event.");
    }
    if (
      today < new Date(checkLimit[0]?.startDate) ||
      today > new Date(checkLimit[0]?.endDate)
    ) {
      setLimitMsgOpen(true);
      return setErrorMsg(
        "Starting 16th December, secure your online entry pass for the event!"
      );
      // return alert(
      //   "The event has not started yet. Please wait for it to begin."
      // );
    }
    setLimitMsgOpen(true);
    setErrorMsg(`You selected "${name}" event. Please confirm to proceed.`);
    setSelectedEvent(eventValue); // Set the selected event
    setTimeout(() => {
      submitButtonRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100); //
  };

  // Language Mappings
  const translations = {
    English: {
      registerForm: "Fill in the details below to get your free pass",
      firstName: "Full Name",
      lastName: "Last Name",
      mobileNumber: "Mobile Number",
      email: "Email",
      register: "REGISTER",
      android: "Android",
      ios: "iOS",
      web: "Web Pass",
      mobile: "Mobile Pass",
      selectEventPlaceholder: "Select an Event",
      event1: "Kumar Vishwas Event 14 December",
      event2: "Kumar Vishwas Event 15 December",
      event3: "Kumar Vishwas Event 16 December",
      event4: "Abhang Vari Event 17 December",
      event5: "Abhijat Marathi Event 18 December",
      event6: "Fusion Event 19 December",
      event7: "Sanam Band Live Event 23 December",
      event8: "Udit Narayan 21 December",
      event9: "Vishal Mishra Event 22 December",
      select: "Choose event",
      selected: "Selected event",
      download: "Download Gate Pass",
      view: "View Gate Pass",
      line: "Register by filling in the required details to receive your online event pass. Don’t forget to download your pass. Please note, duplicate passes will not be issued. Arrive early and keep your gate pass ready.",
      choose: "Choose Event",
      terms: "Terms and Conditions",
      termsAndConditions: {
        title: "Terms and Conditions",
        items: [
          {
            title: "First-Come, First-Serve:",
            description:
              "Free entry is granted on a first-come, first-serve basis. Holding a free pass does not guarantee entry if the venue reaches capacity.",
          },
          {
            title: "Non-Transferable:",
            description:
              "The free entry pass is issued to the individual named on it and is strictly non-transferable. Attempts to sell or exchange the pass will result in cancellation.",
          },
          {
            title: "Age Restriction:",
            description:
              "Entry is subject to age verification. Solo attendees must meet the minimum age requirement (16+) and provide valid ID at the entrance.",
          },
          {
            title: "Limited Validity:",
            description:
              "The free entry pass is valid only for the specified event date and time. It cannot be redeemed for other events or dates.",
          },
          {
            title: "Right to Refuse Entry:",
            description:
              "The organizer reserves the right to deny entry to anyone who fails to comply with the event's dress code, behavioral policies, or safety guidelines, even if they hold a free entry pass.",
          },
        ],
      },
    },
    Hindi: {
      registerForm: "रजिस्टर फॉर्म",
      firstName: "पूरा नाम",
      mobileNumber: "मोबाइल नंबर",
      email: "ईमेल",
      register: "रजिस्टर करें",
      android: "एंड्रॉयड",
      ios: "आईओएस",
      web: "वेब पास",
      mobile: "मोबाइल पास",
      selectEventPlaceholder: "एक कार्यक्रम चुनें",
      event1: "कुमार विश्वास इवेंट 14 दिसंबर",
      event2: "कुमार विश्वास इवेंट 15 दिसंबर",
      event3: "कुमार विश्वास इवेंट 16 दिसंबर",
      event4: "अभंग वारी इवेंट 17 दिसंबर",
      event5: "अभिजात मराठी इवेंट 18 दिसंबर",
      event6: "फ्यूजन इवेंट 19 दिसंबर",
      event7: "विशाल मिश्रा इवेंट 22 दिसंबर",
      event8: "उदित नारायण 21 दिसंबर",
      event9: "विशाल मिश्रा इवेंट 23 दिसंबर",
      download: "गेट पास डाउनलोड करें",
      view: "गेट पास देखें",
      line: "आवश्यक विवरण भरकर पंजीकरण करें और अपना ऑनलाइन इवेंट पास प्राप्त करें। अपना पास डाउनलोड करना न भूलें। कृपया ध्यान दें, डुप्लिकेट पास जारी नहीं किया जाएगा। समय से पहले आएं और अपना गेट पास तैयार रखें।",
      choose: "इवेंट चुनें",
      selected: "चयनित इवेंट",
      terms: "नियम और शर्तें",
      termsAndConditions: {
        title: "नियम और शर्तें",
        items: [
          {
            title: "पहले आओ, पहले पाओ:",
            description:
              "मुफ्त प्रवेश पहले आओ, पहले पाओ के आधार पर दिया जाता है। मुफ्त पास रखने से यह सुनिश्चित नहीं होता कि अगर स्थान भर जाए तो प्रवेश मिलेगा।",
          },
          {
            title: "अप्रत्यावर्तनीय:",
            description:
              "मुफ्त प्रवेश पास उस व्यक्ति को जारी किया जाता है जिसका नाम उस पर है और यह स्थानांतरित नहीं किया जा सकता। पास को बेचना या बदलने की कोशिश से रद्द कर दिया जाएगा।",
          },
          {
            title: "उम्र की सीमा:",
            description:
              "प्रवेश उम्र की जांच के अधीन है। एकल आगंतुकों को न्यूनतम उम्र की आवश्यकता (16+) पूरी करनी होगी और प्रवेश द्वार पर वैध पहचान पत्र प्रदान करना होगा।",
          },
          {
            title: "सीमित वैधता:",
            description:
              "मुफ्त प्रवेश पास केवल निर्दिष्ट इवेंट तिथि और समय के लिए मान्य है। इसे अन्य इवेंट्स या तारीखों के लिए भुनाया नहीं जा सकता।",
          },
          {
            title: "प्रवेश से इनकार करने का अधिकार:",
            description:
              "आयोजक को इवेंट के ड्रेस कोड, व्यवहार नीतियों, या सुरक्षा निर्देशों का पालन न करने वालों को प्रवेश देने का अधिकार है, भले ही उनके पास मुफ्त प्रवेश पास हो।",
          },
        ],
      },
    },
    Marathi: {
      registerForm: "उपस्थित राहण्यासाठी नोंदणी",
      firstName: "पूर्ण नाव",
      mobileNumber: "मोबाइल नंबर",
      email: "ईमेल",
      register: "नोंदणी करा",
      android: "एंड्रॉइड",
      ios: "आयओएस",
      web: "वेब पास",
      mobile: "मोबाइल पास",
      selectEventPlaceholder: "एक कार्यक्रम निवडा",
      event1: "कुमार विश्वास कार्यक्रम 14 डिसेंबर",
      event2: "कुमार विश्वास कार्यक्रम 15 डिसेंबर",
      event3: "कुमार विश्वास कार्यक्रम 16 डिसेंबर",
      event4: "अभंग वारी कार्यक्रम 17 डिसेंबर",
      event5: "अभिजात मराठी कार्यक्रम 18 डिसेंबर",
      event6: "फ्यूजन कार्यक्रम 19 डिसेंबर",
      event7: "उदित नारायण 21 डिसेंबर",
      event8: "विशाल मिश्रा कार्यक्रम 22 डिसेंबर",
      event9: "विशाल मिश्रा कार्यक्रम 23 डिसेंबर",
      download: "गेट पास डाउनलोड करा",
      view: "गेट पास पहा",
      line: "आवश्यक तपशील भरून नोंदणी करा आणि आपला ऑनलाइन इव्हेंट पास प्राप्त करा. आपला पास डाउनलोड करायला विसरू नका. कृपया लक्षात घ्या, डुप्लिकेट पास जारी केला जाणार नाही. लवकर या आणि आपला गेट पास तयार ठेवा.",
      choose: "इव्हेंट निवडा",
      selected: "निवडलेला कार्यक्रम",
      terms: " नियम आणि अटी",
      termsAndConditions: {
        title: "नियम आणि अटी",
        items: [
          {
            title: "प्रथम ये, प्रथम सेवा:",
            description:
              "मोफत प्रवेश प्रथम ये, प्रथम सेवा युक्तीवर दिला जातो. मोफत पास असणे ह्याचा अर्थ नाही की तुम्हाला प्रवेश मिळेल, विशेषतः जर जागा भरलेली असेल.",
          },
          {
            title: "अक्षुण्ण ठेवण्याची अट:",
            description:
              "मोफत प्रवेश पास त्या व्यक्तीस दिला जातो ज्याचं नाव त्यावर आहे आणि हा पास हस्तांतरीत केला जाऊ शकत नाही. पास विक्री किंवा हस्तांतरण केल्यास तो रद्द होईल.",
          },
          {
            title: "वयाची अट:",
            description:
              "प्रवेश वय तपासणीला अधीन आहे. एकटे येणाऱ्यांनी किमान वयाची अट (१६+) पूर्ण करणे आवश्यक आहे आणि प्रवेशद्वारावर वैध ओळखपत्र देणे आवश्यक आहे.",
          },
          {
            title: "मर्यादित वैधता:",
            description:
              "मोफत प्रवेश पास केवळ निर्दिष्ट इव्हेंटच्या तारखेस आणि वेळेस वैध आहे. इतर इव्हेंट्स किंवा तारखांसाठी ते बदलता येणार नाही.",
          },
          {
            title: "प्रवेश नाकारण्याचा अधिकार:",
            description:
              "संगठकाला इव्हेंटच्या पोशाख नियम, वर्तन धोरणे किंवा सुरक्षा मार्गदर्शक तत्त्वांचे पालन न करणाऱ्यांना प्रवेश नाकारण्याचा अधिकार आहे, जरी त्यांच्याकडे मोफत प्रवेश पास असेल.",
          },
        ],
      },
    },
  };
  const currentTranslations = translations[selectedLanguage].termsAndConditions;
  //for developement env && Mongo atlas

  const findID = () => {
    const evnetID = selectedEvent || id;
    if (evnetID === "AbWr1712KSMH") {
      return "6740d0cce25eb4fdeec3e403";
    } else if (evnetID === "AbMr1812KSMH") {
      return "6740d0e1e25eb4fdeec3e405";
    } else if (evnetID === "FuSo1912KSMH") {
      return "6740d102e25eb4fdeec3e407";
    } else if (evnetID === "SBdL2012KSMH") {
      return "6740d117e25eb4fdeec3e409";
    } else if (evnetID === "UdNn2112KSMH") {
      return "6740d12de25eb4fdeec3e40b";
    } else if (evnetID === "VsMa2212KSMH") {
      return "6740d146e25eb4fdeec3e40d";
    } else if (evnetID === "GrMA1312KSMH") {
      return "6740d076e25eb4fdeec3e3fb";
    } else if (evnetID === "KrVw1412KSMH") {
      return "6740d090e25eb4fdeec3e3fd";
    } else if (evnetID === "KrVw1512KSMH") {
      return "6740d09ce25eb4fdeec3e3ff";
    } else if (evnetID === "KrVw1612KSMH") {
      return "6740d0a7e25eb4fdeec3e401";
    }
  };

  // for production
  // const findID = () => {
  //   const evnetID = selectedEvent || id;
  //   if (evnetID === "AbWr1712KSMH") {
  //     return "67432288a2c442c836d4a806";
  //   } else if (evnetID === "AbMr1812KSMH") {
  //     return "674322b2a2c442c836d4a808";
  //   } else if (evnetID === "FuSo1912KSMH") {
  //     return "674322d4a2c442c836d4a80a";
  //   } else if (evnetID === "SBdL2012KSMH") {
  //     return "674322f2a2c442c836d4a80c";
  //   } else if (evnetID === "UdNn2112KSMH") {
  //     return "67432311a2c442c836d4a80e";
  //   } else if (evnetID === "VsMa2212KSMH") {
  //     return "67432332a2c442c836d4a810";
  //   } else if (evnetID === "GrMA1312KSMH") {
  //     return "6743219da2c442c836d4a7fe";
  //   } else if (evnetID === "KrVw1412KSMH") {
  //     return "67432233a2c442c836d4a800";
  //   } else if (evnetID === "KrVw1512KSMH") {
  //     return "6743224ea2c442c836d4a802";
  //   } else if (evnetID === "KrVw1612KSMH") {
  //     return "67432266a2c442c836d4a804";
  //   }
  // };

  const fetchMegaEvents = async () => {
    try {
      const response = await fetch("/api/get/megaEvents", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);

      if (data.status === "success") {
        setMegaEvents(data.data[0]);
      } else {
        console.error("Error in response data:", data);
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch("/api/get/events", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);

        if (data.status === "success") {
          setEvents(data.data);
        } else {
          console.error("Error in response data:", data);
        }
      } catch (error) {
        console.error("Fetch error:", error.message);
      }
    };
    fetchMegaEvents();
    fetchEvents();
  }, []);

  // console.log(errorMsg);

  const onSubmit = async (values) => {
    if (loading) return; // Prevent multiple clicks

    const payload = {
      firstName: values.firstName,
      mobileNumber: values.mobileNumber,
      eventId: findID(),
      pincode: pincode,
      address: address,
      coordinates: { lat: lat, lng: long },
    };

    try {
      // if (!lat) {
      //   setErrorMsg("Please turn on your location");
      //   setLocation(true);
      //   setLimitMsgOpen(true);
      //   return;
      //   //  alert("Plase tern on your location");
      // }

      if (id) {
        const checkLimit = events.filter((event) => event.code === id)[0];
        if (checkLimit.registrationCount >= checkLimit.eventLimit) {
          // setErrorMsg(
          //   "Current entry pass slots are filled. Check back soon for more availability!"
          // );
          // setLimitMsgOpen(true);
          setSelectedEvent(findID());
setLimitModalMsgOpen(true);
          return;
        }
        if (!checkLimit.active) {
          setLimitMsgOpen(true);
          return setErrorMsg(
            "Online passes for this event will be released soon—watch for updates!"
          );
          // alert(
          //   "This event is not active. Please choose another event."
          // );
        }
        if (
          today < new Date(checkLimit?.startDate) ||
          today > new Date(checkLimit?.endDate)
        ) {
          setLimitMsgOpen(true);
          return setErrorMsg(
            "Starting 16th December, secure your online entry pass for the event!"
          );
          //  alert(
          //   "The event has not started yet. Please wait for it to begin."
          // );
        }
      } else if (!selectedEvent) {
        return alert("Select an event for registration");
      }
      // return;
      setLoading(true); // Show loader
      //for developemt environments
      const response = await fetch(
        "/api/save/details",
        // "https://api-dev.bharat-online.com/api/v1/event/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      //for production environments

      // const response = await fetch(
      //   "https://api-dev.bharat-online.com/api/v1/event/register",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Accept: "application/json",
      //     },
      //     body: JSON.stringify(payload),
      //   }
      // );

      //for production environments

      // const response = await fetch(
      //   "https://api.bharat-online.com/api/v1/event/register",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Accept: "application/json",
      //     },
      //     body: JSON.stringify(payload),
      //   }
      // );
      const data = await response.json();
      if (response.ok) {
        setResponse(data);
        // console.log("Registration successful:", data);
        setOpenModal(true);
        setSelectedEvent(null);
        setLoading(false); // Show loader
        setTimeout(() => {
          modalCloseRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 100); //
        // alert("Registration successful!");
      } else {
        console.error("Registration failed:", data);
        if (data?.status === 400) {
          setModalOpen(true);
        } else if (data?.status === 402) {
          setErrorMsg(data?.message);
          setLimitMsgOpen(true);
        } else {
          alert(`${data?.message || "Something went wrong"}`);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error while registering:", error);
      alert("An error occurred. Please try again later.");
    }
  };
  const filteredEvents = id
    ? events.filter((event) => event.code === id)
    : events;

  return (
    <div>
      <Grid container justify="center" spacing={2}>
        <Grid
          item
          style={{ position: "absolute", top: 3, left: 0, cursor: "pointer" }}
          onClick={handleOpen}
        >
          <CiLocationOn size={25} /> <>{getShortAddress(address)}</>
        </Grid>
        <Grid item style={{ position: "absolute", top: -15, right: 0 }}>
          <Select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className={classes.languageSelect}
          >
            <MenuItem value="English">English</MenuItem>
            <MenuItem value="Hindi">हिंदी</MenuItem>
            <MenuItem value="Marathi">मराठी</MenuItem>
          </Select>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Full Address</DialogTitle>
          <DialogContent>
            <Typography variant="body1">{address}</Typography>
          </DialogContent>
        </Dialog>
        {/* Centered Logo */}
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", backgroundColor: "#fffcde" }}
        >
          <img src="/khasdar.jpg" alt="Logo" className={classes.logo} />
        </Grid>
        <Marquee data={translations[selectedLanguage].line} />
        {(today >= new Date(checkLimit?.startDate) &&
          today <= new Date(checkLimit?.endDate)) ||
        !id ? (
          // <Grid
          //   item
          //   md={6}
          //   xs={12}
          //   style={{
          //     background: "linear-gradient(to right, #f8f9fa, #e9ecef)", // Subtle gradient
          //     padding: "16px", // Add spacing around the card
          //   }}
          // >
          <div
            style={{
              background: "url('/Banner.png') no-repeat center center", // Replace with your image URL
              backgroundSize: "cover", // Ensure the image covers the entire area
              minHeight: "85vh", // Full height of the viewport
              display: "flex", // To center the Grid
              justifyContent: "center",
              alignItems: "center",
              minWidth: "90%", //
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              style={{
                background: "linear-gradient(to right, #f8f9fa, #e9ecef)", // Subtle gradient inside the Grid
                padding: "16px", // Add spacing around the card
                borderRadius: "12px", // Rounded corners for the Grid content
              }}
            >
              <Card
                className={classes.padding}
                style={{
                  background: "white", // Clean and simple card background
                  borderRadius: "12px", // Rounded corners
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                  transition: "transform 0.3s, box-shadow 0.3s", // Smooth hover animation
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.boxShadow =
                    "0 8px 16px rgba(0, 0, 0, 0.2)"; // Elevated shadow on hover
                  e.currentTarget.style.transform = "scale(1.05)"; // Slightly enlarge on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.boxShadow =
                    "0 4px 12px rgba(0, 0, 0, 0.1)"; // Reset shadow
                  e.currentTarget.style.transform = "scale(1)"; // Reset size
                }}
              >
                <h1>{translations[selectedLanguage].registerForm}</h1>

                <Formik
                  initialValues={{
                    firstName: "",
                    mobileNumber: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ dirty, isValid }) => (
                    <Form>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              label={translations[selectedLanguage].firstName}
                              variant="standard" // Underline border style
                              fullWidth
                              required
                              name="firstName"
                              component={TextField}
                              className={classes.input}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Field
                              label={
                                translations[selectedLanguage].mobileNumber
                              }
                              variant="standard" // Underline border style
                              fullWidth
                              required
                              name="mobileNumber"
                              component={TextField}
                              className={classes.input}
                            />
                          </Grid>

                          {/* New Dropdown Field */}
                          <Grid item xs={12}>
                            <h3>
                              {" "}
                              {id
                                ? translations[selectedLanguage].selected
                                : translations[selectedLanguage].choose}
                            </h3>
                            <Grid container spacing={2} justifyContent="center">
                              {filteredEvents.map((event) => (
                                <Grid
                                  item
                                  key={event.code}
                                  xs={6}
                                  sm={4}
                                  md={3}
                                  display="flex"
                                  justifyContent="center"
                                  direction="column"
                                  alignItems="center"
                                >
                                  <img
                                    src={event.imageUrl}
                                    alt={event.label}
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      cursor: "pointer",
                                      maxWidth: "120px", // Set a max width for images on mobile
                                      border:
                                        selectedEvent === event.code
                                          ? "3px solid #1976d2"
                                          : "none", // Highlight selected image
                                      borderRadius: "8px", // Optional: rounded corners
                                      boxShadow:
                                        selectedEvent === event.code
                                          ? "0px 0px 10px rgba(25, 118, 210, 0.5)"
                                          : "none", // Optional: add shadow for selected
                                      animation:
                                        "hoverEffect 2s infinite ease-in-out", // Apply animation
                                    }}
                                    onClick={() =>
                                      handleImageClick(
                                        event.code,
                                        event?.eventName
                                      )
                                    } // Handle image click to select
                                  />

                                  <Typography
                                    variant="body2"
                                    align="center"
                                    style={{
                                      marginTop: "2px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {event.eventDate &&
                                      formatDateToDDMMM(event.eventDate)}
                                  </Typography>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Button
                        ref={submitButtonRef}
                        disabled={loading} // Disable when loading
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.button}
                        style={{
                          padding: "10px 30px",
                          fontSize: "18px",
                          fontWeight: "bold",
                          borderRadius: "30px",
                          background: loading
                            ? "linear-gradient(135deg, #bbb, #ddd)" // Dim color for loading state
                            : "linear-gradient(135deg, #cc5200, #b065a1)",
                          color: "white",
                          textTransform: "uppercase",
                          boxShadow: loading
                            ? "none"
                            : "0 4px 10px rgba(255, 127, 50, 0.3)",
                          transition:
                            "transform 0.3s ease, box-shadow 0.3s ease",
                        }}
                        onMouseEnter={(e) => {
                          if (!loading) {
                            e.target.style.transform = "scale(1.05)";
                            e.target.style.boxShadow =
                              "0 6px 15px rgba(255, 127, 50, 0.5)";
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (!loading) {
                            e.target.style.transform = "scale(1)";
                            e.target.style.boxShadow =
                              "0 4px 10px rgba(255, 127, 50, 0.3)";
                          }
                        }}
                      >
                        {loading ? (
                          <CircularProgress
                            size={24}
                            style={{ color: "white" }}
                          />
                        ) : (
                          translations[selectedLanguage].register
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Grid>
          </div>
        ) : // <Grid
        //   item
        //   md={6}
        //   xs={12}
        //   style={{
        //     background: "linear-gradient(to right, #f8f9fa, #e9ecef)", // Subtle gradient inside the Grid
        //     padding: "16px", // Add spacing around the card
        //     borderRadius: "12px", // Rounded corners for the Grid content
        //   }}
        // >
        //   <Card
        //     className={classes.padding}
        //     style={{
        //       background: "white", // Clean and simple card background
        //       borderRadius: "12px", // Rounded corners
        //       boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
        //       transition: "transform 0.3s, box-shadow 0.3s", // Smooth hover animation
        //     }}
        //     onMouseEnter={(e) => {
        //       e.currentTarget.style.boxShadow =
        //         "0 8px 16px rgba(0, 0, 0, 0.2)"; // Elevated shadow on hover
        //       e.currentTarget.style.transform = "scale(1.05)"; // Slightly enlarge on hover
        //     }}
        //     onMouseLeave={(e) => {
        //       e.currentTarget.style.boxShadow =
        //         "0 4px 12px rgba(0, 0, 0, 0.1)"; // Reset shadow
        //       e.currentTarget.style.transform = "scale(1)"; // Reset size
        //     }}
        //   >
        //     <h1>{translations[selectedLanguage].registerForm}</h1>

        //     <Formik
        //       initialValues={{
        //         firstName: "",
        //         mobileNumber: "",
        //       }}
        //       validationSchema={validationSchema}
        //       onSubmit={onSubmit}
        //     >
        //       {({ dirty, isValid }) => (
        //         <Form>
        //           <CardContent>
        //             <Grid container spacing={2}>
        //               <Grid item xs={12}>
        //                 <Field
        //                   label={translations[selectedLanguage].firstName}
        //                   variant="standard" // Underline border style
        //                   fullWidth
        //                   required
        //                   name="firstName"
        //                   component={TextField}
        //                   className={classes.input}
        //                 />
        //               </Grid>

        //               <Grid item xs={12}>
        //                 <Field
        //                   label={translations[selectedLanguage].mobileNumber}
        //                   variant="standard" // Underline border style
        //                   fullWidth
        //                   required
        //                   name="mobileNumber"
        //                   component={TextField}
        //                   className={classes.input}
        //                 />
        //               </Grid>

        //               {/* New Dropdown Field */}
        //               <Grid item xs={12}>
        //                 <h3>
        //                   {" "}
        //                   {id
        //                     ? translations[selectedLanguage].selected
        //                     : translations[selectedLanguage].choose}
        //                 </h3>
        //                 <Grid container spacing={2} justifyContent="center">
        //                   {filteredEvents.map((event) => (
        //                     <Grid
        //                       item
        //                       key={event.code}
        //                       xs={6}
        //                       sm={4}
        //                       md={3}
        //                       display="flex"
        //                       justifyContent="center"
        //                       direction="column"
        //                       alignItems="center"
        //                     >
        //                       <img
        //                         src={event.imageUrl}
        //                         alt={event.label}
        //                         style={{
        //                           width: "100%",
        //                           height: "auto",
        //                           cursor: "pointer",
        //                           maxWidth: "120px", // Set a max width for images on mobile
        //                           border:
        //                             selectedEvent === event.code
        //                               ? "3px solid #1976d2"
        //                               : "none", // Highlight selected image
        //                           borderRadius: "8px", // Optional: rounded corners
        //                           boxShadow:
        //                             selectedEvent === event.code
        //                               ? "0px 0px 10px rgba(25, 118, 210, 0.5)"
        //                               : "none", // Optional: add shadow for selected
        //                           animation:
        //                             "hoverEffect 2s infinite ease-in-out", // Apply animation
        //                         }}
        //                         onClick={() =>
        //                           handleImageClick(
        //                             event.code,
        //                             event?.eventName
        //                           )
        //                         } // Handle image click to select
        //                       />

        //                       <Typography
        //                         variant="body2"
        //                         align="center"
        //                         style={{
        //                           marginTop: "2px",
        //                           fontWeight: "600",
        //                         }}
        //                       >
        //                         {event.eventDate &&
        //                           formatDateToDDMMM(event.eventDate)}
        //                       </Typography>
        //                     </Grid>
        //                   ))}
        //                 </Grid>
        //               </Grid>
        //             </Grid>
        //           </CardContent>
        //           <Button
        //             ref={submitButtonRef}
        //             disabled={loading} // Disable when loading
        //             variant="contained"
        //             color="primary"
        //             type="submit"
        //             className={classes.button}
        //             style={{
        //               padding: "10px 30px",
        //               fontSize: "16px",
        //               fontWeight: "bold",
        //               borderRadius: "30px",
        //               background: loading
        //                 ? "linear-gradient(135deg, #bbb, #ddd)" // Dim color for loading state
        //                 : "linear-gradient(135deg, #FF7F32, #F3A6D7)",
        //               color: "white",
        //               textTransform: "uppercase",
        //               boxShadow: loading
        //                 ? "none"
        //                 : "0 4px 10px rgba(255, 127, 50, 0.3)",
        //               transition: "transform 0.3s ease, box-shadow 0.3s ease",
        //             }}
        //             onMouseEnter={(e) => {
        //               if (!loading) {
        //                 e.target.style.transform = "scale(1.05)";
        //                 e.target.style.boxShadow =
        //                   "0 6px 15px rgba(255, 127, 50, 0.5)";
        //               }
        //             }}
        //             onMouseLeave={(e) => {
        //               if (!loading) {
        //                 e.target.style.transform = "scale(1)";
        //                 e.target.style.boxShadow =
        //                   "0 4px 10px rgba(255, 127, 50, 0.3)";
        //               }
        //             }}
        //           >
        //             {loading ? (
        //               <CircularProgress
        //                 size={24}
        //                 style={{ color: "white" }}
        //               />
        //             ) : (
        //               translations[selectedLanguage].register
        //             )}
        //           </Button>
        //         </Form>
        //       )}
        //     </Formik>
        //   </Card>
        // </Grid>
        today < new Date(checkLimit?.startDate) ? (
          <p
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#FF5733",
            }}
          >
            Starting 16th December, secure your online entry pass for the event!
          </p>
        ) : today > new Date(checkLimit?.endDate) ? (
          // Event has ended
          <p>
            This Event is No Longer Active! Thank you for your interest. Stay
            tuned for upcoming events.
          </p>
        ) : null}

        {/* )} */}
        {/* QR Buttons Section */}
        {/* {showQrButtons && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "20px" }}>
              <div style={{ textAlign: "center" }}>
                <a href="https://apps.apple.com/in/developer/lutec-india-it-services-private-limited/id1695129464" target="_blank" rel="noopener noreferrer">
                  <img src="/ios.webp" alt="App Store" style={{ width: "100px", marginBottom: "10px" }} />
                </a>
                <img
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://apps.apple.com/in/developer/lutec-india-it-services-private-limited/id1695129464`}
                  alt="iOS QR Code"
                  style={{ display: "block", margin: "auto", width: "80px" }}
                />
              </div>

              <div style={{ textAlign: "center" }}>
                <a href="https://play.google.com/store/apps/details?id=com.bharatonline.customer.app" target="_blank" rel="noopener noreferrer">
                  <img src="/android.webp" alt="Play Store" style={{ width: "100px", marginBottom: "10px" }} />
                </a>
                <img
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://play.google.com/store/apps/details?id=com.bharatonline.customer.app`}
                  alt="Android QR Code"
                  style={{ display: "block", margin: "auto", width: "80px" }}
                />
              </div>
            </div>
          </Grid>
        )} */}
      </Grid>
      <RegistrationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        modalCloseRef={modalCloseRef}

        // onDownload={handleDownload}
      />
      <MobileNumberAlert
        open={isModalOpen}
        onClose={handleModalClose}
        openClaim={openClaim}
        setOpenClaim={setOpenClaim}
        setResponse={setResponse}
        selectedEvent={findID()}
      />
      <LimitMsgModal
        open={limitModalMsgOpen}
        onClose={handleModalClose}
        openClaim={openClaim}
        setOpenClaim={setOpenClaim}
        setResponse={setResponse}
        selectedEvent={findID()}
      />

      {response && (
        // <Receipt
        //   event={response}
        //   selectedEvent={selectedEvent || id}
        //   download={translations[selectedLanguage].download}
        //   view={translations[selectedLanguage].view}
        //   modalCloseRef={modalCloseRef}
        // />
        <InvitationCard
          event={response}
          selectedEvent={selectedEvent || id}
          download={translations[selectedLanguage].download}
          view={translations[selectedLanguage].view}
          modalCloseRef={modalCloseRef}
        />
      )}

      <div
        style={{
          maxWidth: "600px", // Limit the width to a card size
          margin: "0 auto", // Center the content horizontally
          textAlign: "left", // Keep the text alignment left
          padding: "16px", // Add some padding for spacing
        }}
      >
        <h4 style={{ marginBottom: "10px", paddingLeft: "10px" }}>
          {translations[selectedLanguage].terms}
        </h4>
        <small>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px", margin: 0 }}>
            {currentTranslations.items.map((item, index) => (
              <li key={index}>
                <strong>{item.title}</strong> {item.description}
              </li>
            ))}
          </ul>
        </small>
      </div>
      {/* <div style={{ textAlign: "left" }}>
        <h4 style={{ marginBottom: "10px", paddingLeft: "10px" }}>
          {translations[selectedLanguage].terms}
        </h4>
        <small>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px", margin: 0 }}>
            {currentTranslations.items.map((item, index) => (
              <li key={index}>
                <strong>{item.title}</strong> {item.description}
              </li>
            ))}
          </ul>
        </small>
       
      </div> */}
      <Dialog open={limitMsgOpen} onClose={handleLimitClose}>
        <DialogTitle>{errorMsg}</DialogTitle>
        {/* <DialogContent>
          <p>
            We are thrilled by your enthusiasm! 🚀 However, all gate passes for
            this event are now fully booked. 😔
          </p>
          <p>
            💡 Please stay connected with us and revisit the site for updates on
            new events or additional pass availability. 🔗✨
          </p>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleLimitClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserForm;

{
  /* Buttons 
         <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setShowForm(true);
              setShowQrButtons(false);
            }}
          >
            {translations[selectedLanguage].web}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setShowQrButtons(true);
              setShowForm(false);
            }}
          >
            {translations[selectedLanguage].mobile}
          </Button>
        </Grid> 
         Form Section 
         {showForm && ( 
          <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "red",
            fontWeight: "bold",
            fontSize: "16px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "inline-block",
              animation: "scrollText 200s linear infinite",
            }}
          >
            <span>{translations[selectedLanguage].line}</span>
            <span>{translations[selectedLanguage].line}</span>
            <span>{translations[selectedLanguage].line}</span>
            <span>{translations[selectedLanguage].line}</span>
            <span>{translations[selectedLanguage].line}</span>
          </div>
        </div> 
        <style>
          {`
    @keyframes scrollText {
      0% {
        transform: translateX(100%); 
      }
      100% {
        transform: translateX(-100%); 
      }
    }
  `}
        </style>*/
}
// const eventImages = [
//   {
//     value: "KrVw1412KSMH",
//     imageUrl: "/1.png",
//     label: translations[selectedLanguage].event1,
//     date: "14 Dec",
//   },
//   {
//     value: "KrVw1512KSMH",
//     imageUrl: "/1.png",
//     label: translations[selectedLanguage].event2,
//     date: "15 Dec",
//   },
//   {
//     value: "KrVw1612KSMH",
//     imageUrl: "/1.png",
//     label: translations[selectedLanguage].event3,
//     date: "16 Dec",
//   },
//   {
//     value: "AbWr1712KSMH",
//     imageUrl: "/2.png",
//     label: translations[selectedLanguage].event4,
//     date: "17 Dec",
//   },
//   {
//     value: "AbMr1812KSMH",
//     imageUrl: "/3.png",
//     label: translations[selectedLanguage].event5,
//     date: "18 Dec",
//   },
//   {
//     value: "FuSo1912KSMH",
//     imageUrl: "/4.png",
//     label: translations[selectedLanguage].event6,
//     date: "19 Dec",
//   },
//   {
//     value: "SBdL2012KSMH",
//     imageUrl: "/5.png",
//     label: translations[selectedLanguage].event7,
//     date: "20 Dec",
//   },
//   {
//     value: "UdNn2112KSMH",
//     imageUrl: "/6.png",
//     label: translations[selectedLanguage].event8,
//     date: "21 Dec",
//   },
//   {
//     value: "VsMa2212KSMH",
//     imageUrl: "/7.png",
//     label: translations[selectedLanguage].event9,
//     date: "22 Dec",
//   },
// ];
