// Marquee.js
import React, { useState } from "react";

const Marquee = ({ data }) => {
  const [isPaused, setIsPaused] = useState(false);

  return (
    <div
      className="marquee-container"
      onMouseOver={() => setIsPaused(true)}
      onMouseOut={() => setIsPaused(false)}
    >
      <div className={`marquee-content ${isPaused ? "paused" : ""}`}>
        <p>{data}</p>
      </div>
    </div>
  );
};

export default Marquee;
