// import './App.css';
// import UserForm from './Form';

// function App() {
//   return (
//     <div className="App">
//       <UserForm />
//     </div>
//   );
// }

// export default App;

import { createBrowserRouter } from "react-router-dom";
import "./App.css";
import UserForm from "./Form";
import AdminDashboard from "./Admin/dashboard";
import AdminLayout from "./Admin/Drawer";
import DataTable from "./Admin/Settings";
import UserList from "./Admin/userList";
import Redownload from "./Admin/Redownload";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="App">
        <UserForm />
      </div>
    ),
  },
  {
    path: "/:id",
    element: (
      <div className="App">
        <UserForm />
      </div>
    ),
  },
  {
    path: "/admin/dash",
    element: (
      <AdminLayout>
        <div className="App">
          <AdminDashboard />
        </div>
      </AdminLayout>
    ),
  },
  {
    path: "/settings",
    element: (
      // <AdminLayout>
      <div className="App">
        <DataTable />
      </div>
      // </AdminLayout>
    ),
  },
  {
    path: "/userList",
    element: (
      <div className="App">
        <UserList />
      </div>
    ),
  },
  {
    path: "/redownload",
    element: 
     <div className="App">
      <Redownload />
     </div>
  },
]);

export default router;
