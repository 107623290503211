import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./dash.css";
import { Button, Form, Input, Select, Table } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons"; // Ant Design icons
import ChartComponent from "./Graph";
import CityAttendanceChart from "./Graph";
// Fix marker icon issue for Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

const AdminDashboard = () => {
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [resultsCount, setResultsCount] = useState(null);
  const [eventValue, setEventValue] = useState(null);
  const [showTable, setShowTable] = useState(false);

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => {
      setShowTable(window.innerWidth >= 768); // Automatically show table for web
    };

    handleResize(); // Set initial state based on screen width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleTable = () => {
    setShowTable((prev) => !prev); // Toggle table visibility
  };

  const handleMarkerMouseOver = (map, coordinates, pincode, users) => {
    const point = map.latLngToContainerPoint(coordinates); // Convert lat/lng to screen coordinates
    setPopupInfo({
      point,
      pincode,
      users,
    });
  };

  const handleMarkerMouseOut = () => {
    setPopupInfo(null); // Clear popup info
  };

  const MapWithEvents = () => {
    const map = useMap();
    return (
      <>
        {Object.keys(groupedData).map((pincode) => {
          const { users, coordinates } = groupedData[pincode];

          if (!coordinates?.lat || !coordinates?.lng || !pincode) {
            return null; // Skip invalid data
          }

          return (
            <Marker
              key={pincode}
              position={[coordinates.lat, coordinates.lng]}
              eventHandlers={{
                mouseover: () =>
                  handleMarkerMouseOver(
                    map,
                    { lat: coordinates.lat, lng: coordinates.lng },
                    pincode,
                    users
                  ),
                mouseout: handleMarkerMouseOut,
              }}
            />
          );
        })}
      </>
    );
  };
  useEffect(() => {
    fetchEvents();
    fetchUsers();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch("/api/get/events", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        setList(data.data);
      } else {
        console.error("Error in response data:", data);
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await fetch("/api/get/users", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);

      if (data.status === "success") {
        setData(data.data);
      } else {
        console.error("Error in response data:", data);
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };
  const handleSearch = () => {
    if (eventValue === true) {
      if (pincode) {
        setFilterData(
          data?.filter((item) => item?.pincode === Number(pincode))
        );
      } else {
        setFilterData(data);
      }
    } else {
      if (eventValue && pincode) {
        setFilterData(
          data?.filter(
            (item) =>
              item.eventId === eventValue && item?.pincode === Number(pincode)
          )
        );
      } else {
        if (eventValue) {
          setFilterData(data?.filter((item) => item.eventId === eventValue));
        } else if (pincode) {
          setFilterData(
            data?.filter((item) => item.pincode === Number(pincode))
          );
        }
      }
    }
  };

  const handleOther = (value) => {
    // console.log(value);
    setEventValue(value);
    // setFilterData(data?.filter((item) => item.eventId === value));
  };
  // console.log(data);

  const groupByPincode = (data) => {
    return data.reduce((acc, { pincode, users = 1, coordinates }) => {
      if (acc[pincode]) {
        acc[pincode].users += users;
      } else {
        acc[pincode] = { users, coordinates };
      }
      return acc;
    }, {});
  };

  const groupedData = groupByPincode(filterData ? filterData : data);

  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
  ];

  // Slice data to show only 10 entries
  const tableData = (list || []).slice(0, 10).map((item, index) => ({
    key: index,
    eventName: item.eventName,
    count: item.registrationCount,
  }));
  return (
    <>
      <MapContainer
        center={[21.14626174870139, 79.06168668482383]} // Center of India
        zoom={11}
        style={{
          height: "85vh",
          width: "100%",
          border: "1px solid ", // Optional: light gray border
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
        }}
      >
        <div
          style={{
            width: "230px",
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1000, // Ensure it appears above the map
            backgroundColor: "rgba(255, 255, 255, 0.9)", // Adjust opacity to enhance white
            border: "1px solid #ccc", // Add a light gray border for contrast
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", // Subtle shadow for elevation
            padding: "10px", // Optional: add some spacing around
          }}
          onClick={(e) => e.stopPropagation()} // Prevent map events from closing the dropdown
          onMouseDown={(e) => e.stopPropagation()} // Stops mouse-down from propagating
        >
          {/* Select Dropdown */}
          <Form.Item style={{ marginBottom: "3px" }}>
            <Select
              name="board_area"
              size="medium"
              showSearch
              placeholder="Select Event"
              onChange={handleOther}
            >
              <Select.Option value={true}>All Events</Select.Option>
              {list &&
                list.map((item, index) => (
                  <Select.Option key={index} value={item._id}>
                    {item.eventName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item style={{ marginBottom: "3px" }}>
            <Select
              size="medium"
              showSearch
              placeholder="Select Pincode"
              onChange={(e) => setPincode(e)}
            >
              {data &&
                Array.from(
                  new Set(
                    data
                      .filter((item) => item.pincode) // Include only items with a valid pincode
                      .map((item) => item.pincode) // Extract pincode values
                  )
                ).map((pincode, index) => (
                  <Select.Option key={index} value={pincode}>
                    {pincode}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: "3px" }}>
            <Button
              type="primary"
              size="medium"
              onClick={handleSearch}
              style={{ width: "100%" }}
            >
              Search
            </Button>
          </Form.Item>

          {/* Display Results */}
          {filterData !== null && (
            <div style={{ marginTop: "5px", textAlign: "center" }}>
              <strong>Results Count: {filterData?.length}</strong>
            </div>
          )}
          <div>
            {/* Toggle Button */}
            <div style={{ marginBottom: "10px", textAlign: "center" }}>
              <Button
                type="primary"
                onClick={toggleTable}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {showTable ? (
                  <>
                    Close Table <UpOutlined style={{ marginLeft: "8px" }} />
                  </>
                ) : (
                  <>
                    View Count <DownOutlined style={{ marginLeft: "8px" }} />
                  </>
                )}
              </Button>
            </div>

            {/* Table */}
            {showTable && (
              <div
                style={{
                  maxHeight: "500px", // Limit height
                  overflowY: "auto", // Enable vertical scrolling
                  marginTop: "3px",
                  border: "1px solid #ccc", // Add border for better visibility
                  borderRadius: "4px",
                }}
              >
                <Table
                  dataSource={tableData}
                  columns={columns}
                  pagination={false}
                  bordered
                  scroll={{ x: "auto" }}
                  rowClassName={() => "compressed-row"}
                />
              </div>
            )}
          </div>
          {/* 
          <div
            style={{
              maxHeight: "500px", // Limit height
              overflowY: "auto", // Enable vertical scrolling
              marginTop: "3px",
              border: "1px solid #ccc", // Add border for better visibility
              borderRadius: "4px",
            }}
          >
            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
              bordered
              scroll={{ x: "auto" }}
              rowClassName={() => "compressed-row"}
            />
          </div> */}
          <style jsx>{`
            .compressed-row {
              font-size: 12px; /* Smaller font size */
              padding: 4px; /* Less padding */
            }

            .compressed-row td {
              padding: 4px 8px !important; /* Adjust cell padding */
            }

            .ant-table-cell {
              padding: 4px 8px !important; /* Adjust padding globally if needed */
            }

            .ant-table {
              font-size: 12px; /* Global font size for the table */
            }
          `}</style>
        </div>

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <div
          id="custom-attribution"
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            zIndex: 1000,
            backgroundColor: "white",
            padding: "5px",
            borderRadius: "5px",
            textDecoration: "none",
            color: "#007BFF",
            display: "flex", // Align children in a row
            alignItems: "center", // Center vertically
          }}
        >
          <img
            src="/bharatOnline.png"
            alt="bharatOnline"
            crossOrigin="anonymous"
            style={{
              marginRight: "8px",
              height: "20px", // Adjust the size as needed
            }}
          />
          <a
            href="https://www.bharat-online.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pruthvi
          </a>
        </div>

        <MapWithEvents />

        {popupInfo && (
          <div
            style={{
              position: "absolute",
              left: `${popupInfo.point.x}px`,
              top: `${popupInfo.point.y - 35}px`,
              backgroundColor: "white",
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              zIndex: 1000,
              transform: "translate(-50%, -100%)", // Center above the point
            }}
          >
            <strong>Pincode:</strong> {popupInfo.pincode} <br />
            <strong>Total Users:</strong> {popupInfo.users}
          </div>
        )}
      </MapContainer>
      {/* <CityAttendanceChart data={list} /> */}
    </>
  );
};

export default AdminDashboard;

{
  /* {Object.keys(groupedData).map((pincode) => {
          const { users, coordinates } = groupedData[pincode];

          // Skip entries without valid lat, lng, or pincode
          if (!coordinates?.lat || !coordinates?.lng || !pincode) {
            return null; // Skip this iteration if any required value is missing
          }
          return (
            <Marker
              key={pincode}
              position={[coordinates?.lat, coordinates?.lng]}
            >
              <Popup>
                <strong>Pincode:</strong> {pincode} <br />
                <strong>Total Users:</strong> {users}
              </Popup>
            </Marker>
          );
        })} */
}
// import React from "react";
// import { MapContainer, TileLayer, Circle, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";

// const AdminDashboard = () => {

//   const getColor = (userCount) => {
//     if (userCount > 20) return "red";
//     if (userCount > 10) return "orange";
//     return "green";
//   };
// const pincodeData = [
//   { pincode: "110001", users: 15, coordinates: { lat: 28.6304, lng: 77.2177 } },
//   { pincode: "400001", users: 10, coordinates: { lat: 18.9388, lng: 72.8354 } },
//   // More data points
// ];

//   return (
//     <MapContainer
//       center={[20.5937, 78.9629]} // Center of India
//       zoom={5}
//       style={{ height: "600px", width: "100%" }}
//     >
//       <TileLayer
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />
//       {pincodeData.map((item, index) => (
//         <Circle
//           key={index}
//           center={[item.coordinates.lat, item.coordinates.lng]}
//           radius={item.users * 5000} // Scale the circle size
//           color={getColor(item.users)}
//           fillOpacity={0.5}
//         >
//           <Popup>
//             <div>
//               <strong>Pincode:</strong> {item.pincode} <br />
//               <strong>Users:</strong> {item.users}
//             </div>
//           </Popup>
//         </Circle>
//       ))}
//     </MapContainer>
//   );
// };

// export default AdminDashboard;

// import React from "react";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// // Fix marker icon issue for Leaflet
// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
//   iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
//   shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
// });

// const data = [
//   { pincode: "440001", users: 20, coordinates: { lat: 21.1458, lng: 79.0882 } }, // Nagpur
//   { pincode: "440002", users: 18, coordinates: { lat: 21.1613, lng: 79.0735 } }, // Nagpur
//   { pincode: "440003", users: 22, coordinates: { lat: 21.1658, lng: 79.1143 } }, // Nagpur
//   { pincode: "440004", users: 12, coordinates: { lat: 21.1631, lng: 79.1211 } }, // Nagpur
//   { pincode: "442001", users: 15, coordinates: { lat: 20.0489, lng: 79.2082 } }, // Chandrapur
//   { pincode: "442101", users: 9, coordinates: { lat: 19.9654, lng: 79.3036 } },  // Chandrapur
//   { pincode: "445001", users: 10, coordinates: { lat: 20.3888, lng: 78.1251 } }, // Yavatmal
//   { pincode: "445002", users: 8, coordinates: { lat: 20.3992, lng: 78.1203 } },  // Yavatmal
//   { pincode: "444001", users: 19, coordinates: { lat: 20.9374, lng: 77.7796 } }, // Amravati
//   { pincode: "444002", users: 14, coordinates: { lat: 20.9360, lng: 77.7571 } }, // Amravati
//   { pincode: "441001", users: 16, coordinates: { lat: 20.7635, lng: 78.6198 } }, // Wardha
//   { pincode: "441002", users: 13, coordinates: { lat: 20.7479, lng: 78.6171 } }, // Wardha
//   { pincode: "443001", users: 7, coordinates: { lat: 20.7033, lng: 76.9833 } },  // Buldhana
//   { pincode: "443002", users: 11, coordinates: { lat: 20.7216, lng: 76.9614 } }, // Buldhana
//   { pincode: "441801", users: 25, coordinates: { lat: 21.0986, lng: 79.4863 } }, // Gondia
//   { pincode: "441802", users: 20, coordinates: { lat: 21.0841, lng: 79.4675 } }, // Gondia
//   { pincode: "441601", users: 12, coordinates: { lat: 21.3667, lng: 79.6533 } }, // Bhandara
//   { pincode: "441602", users: 9, coordinates: { lat: 21.3525, lng: 79.6691 } },  // Bhandara
//   { pincode: "442401", users: 15, coordinates: { lat: 20.2724, lng: 80.0052 } }, // Gadchiroli
//   { pincode: "442402", users: 12, coordinates: { lat: 20.3724, lng: 79.9943 } }, // Gadchiroli
//   { pincode: "445303", users: 18, coordinates: { lat: 20.0153, lng: 78.1302 } }, // Wani
//   { pincode: "445304", users: 9, coordinates: { lat: 19.9716, lng: 78.1579 } },  // Wani
//   { pincode: "441104", users: 21, coordinates: { lat: 20.9474, lng: 78.9719 } }, // Hinganghat
//   { pincode: "441105", users: 15, coordinates: { lat: 20.9226, lng: 78.9867 } }, // Hinganghat
//   { pincode: "444601", users: 14, coordinates: { lat: 20.9149, lng: 77.7482 } }, // Dhamangaon
//   { pincode: "444602", users: 12, coordinates: { lat: 20.9083, lng: 77.7205 } }, // Dhamangaon
//   { pincode: "441203", users: 10, coordinates: { lat: 20.8336, lng: 78.4898 } }, // Arvi
//   { pincode: "441204", users: 8, coordinates: { lat: 20.8547, lng: 78.4664 } },  // Arvi
//   { pincode: "442707", users: 23, coordinates: { lat: 20.0875, lng: 79.6750 } }, // Mul
//   { pincode: "442708", users: 16, coordinates: { lat: 20.0625, lng: 79.6917 } }, // Mul
//   { pincode: "442302", users: 12, coordinates: { lat: 20.1382, lng: 79.8589 } }, // Ballarpur
//   { pincode: "442303", users: 9, coordinates: { lat: 20.1554, lng: 79.8753 } },  // Ballarpur
//   { pincode: "444905", users: 8, coordinates: { lat: 21.0485, lng: 77.3479 } },  // Khamgaon
//   { pincode: "444906", users: 6, coordinates: { lat: 21.0369, lng: 77.3539 } },  // Khamgaon
//   { pincode: "444501", users: 10, coordinates: { lat: 21.1602, lng: 76.9273 } }, // Akola
//   { pincode: "444502", users: 13, coordinates: { lat: 21.1472, lng: 76.9494 } }, // Akola
//   { pincode: "441908", users: 15, coordinates: { lat: 20.6107, lng: 78.1234 } }, // Pulgaon
//   { pincode: "441909", users: 18, coordinates: { lat: 20.6347, lng: 78.1213 } }, // Pulgaon
//   { pincode: "441801", users: 7, coordinates: { lat: 21.2199, lng: 79.6591 } },  // Pauni
//   { pincode: "441802", users: 8, coordinates: { lat: 21.2303, lng: 79.6324 } },  // Pauni
//   { pincode: "442202", users: 14, coordinates: { lat: 19.8889, lng: 79.8292 } }, // Rajura
//   { pincode: "442203", users: 11, coordinates: { lat: 19.8669, lng: 79.8481 } }, // Rajura
//   { pincode: "441306", users: 20, coordinates: { lat: 20.6672, lng: 78.8016 } }, // Hingna
//   { pincode: "441307", users: 15, coordinates: { lat: 20.6544, lng: 78.7789 } }, // Hingna
//   { pincode: "445206", users: 12, coordinates: { lat: 19.9500, lng: 78.1133 } }, // Pandharkawda
//   { pincode: "445207", users: 9, coordinates: { lat: 19.9301, lng: 78.1244 } },  // Pandharkawda
// ];

// const AdminDashboard = () => {
//   return (
//     <MapContainer
//       center={[20.5937, 78.9629]} // Center of India
//       zoom={5}
//       style={{ height: "800px", width: "100%" }}
//     >
//       <TileLayer
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />
//       {data.map((item, index) => (
//         <Marker
//           key={index}
//           position={[item.coordinates.lat, item.coordinates.lng]}
//         >
//           <Popup>
//             <strong>Pincode:</strong> {item.pincode} <br />
//             <strong>Users:</strong> {item.users}
//           </Popup>
//         </Marker>
//       ))}
//     </MapContainer>
//   );
// };

// export default AdminDashboard;

// const data = [
//   { pincode: "440001", coordinates: { lat: 21.1458, lng: 79.0882 } }, // Nagpur
//   { pincode: "440001", coordinates: { lat: 21.1613, lng: 79.0735 } }, // Nagpur
//   { pincode: "440001", coordinates: { lat: 21.1658, lng: 79.1143 } }, // Nagpur
//   { pincode: "440001", coordinates: { lat: 21.1631, lng: 79.1211 } }, // Nagpur
//   { pincode: "442001", coordinates: { lat: 20.0489, lng: 79.2082 } }, // Chandrapur
//   { pincode: "442101", coordinates: { lat: 19.9654, lng: 79.3036 } }, // Chandrapur
//   { pincode: "445001", coordinates: { lat: 20.3888, lng: 78.1251 } }, // Yavatmal
//   { pincode: "445002", coordinates: { lat: 20.3992, lng: 78.1203 } }, // Yavatmal
//   { pincode: "444001", coordinates: { lat: 20.9374, lng: 77.7796 } }, // Amravati
//   { pincode: "444002", coordinates: { lat: 20.936, lng: 77.7571 } }, // Amravati
//   { pincode: "441001", coordinates: { lat: 20.7635, lng: 78.6198 } }, // Wardha
//   { pincode: "441002", coordinates: { lat: 20.7479, lng: 78.6171 } }, // Wardha
//   { pincode: "443001", coordinates: { lat: 20.7033, lng: 76.9833 } }, // Buldhana
//   { pincode: "443002", coordinates: { lat: 20.7216, lng: 76.9614 } }, // Buldhana
//   { pincode: "441801", coordinates: { lat: 21.0986, lng: 79.4863 } }, // Gondia
//   { pincode: "441802", coordinates: { lat: 21.0841, lng: 79.4675 } }, // Gondia
//   { pincode: "441601", coordinates: { lat: 21.3667, lng: 79.6533 } }, // Bhandara
//   { pincode: "441602", coordinates: { lat: 21.3525, lng: 79.6691 } }, // Bhandara
//   { pincode: "442401", coordinates: { lat: 20.2724, lng: 80.0052 } }, // Gadchiroli
//   { pincode: "442402", coordinates: { lat: 20.3724, lng: 79.9943 } }, // Gadchiroli
//   { pincode: "445303", coordinates: { lat: 20.0153, lng: 78.1302 } }, // Wani
//   { pincode: "445304", coordinates: { lat: 19.9716, lng: 78.1579 } }, // Wani
//   { pincode: "441104", coordinates: { lat: 20.9474, lng: 78.9719 } }, // Hinganghat
//   { pincode: "441105", coordinates: { lat: 20.9226, lng: 78.9867 } }, // Hinganghat
//   { pincode: "444601", coordinates: { lat: 20.9149, lng: 77.7482 } }, // Dhamangaon
//   { pincode: "444602", coordinates: { lat: 20.9083, lng: 77.7205 } }, // Dhamangaon
//   { pincode: "441203", coordinates: { lat: 20.8336, lng: 78.4898 } }, // Arvi
//   { pincode: "441204", coordinates: { lat: 20.8547, lng: 78.4664 } }, // Arvi
//   { pincode: "442707", coordinates: { lat: 20.0875, lng: 79.675 } }, // Mul
//   { pincode: "442708", coordinates: { lat: 20.0625, lng: 79.6917 } }, // Mul
//   { pincode: "442302", coordinates: { lat: 20.1382, lng: 79.8589 } }, // Ballarpur
//   { pincode: "442303", coordinates: { lat: 20.1554, lng: 79.8753 } }, // Ballarpur
//   { pincode: "444905", coordinates: { lat: 21.0485, lng: 77.3479 } }, // Khamgaon
//   { pincode: "444906", coordinates: { lat: 21.0369, lng: 77.3539 } }, // Khamgaon
//   { pincode: "444501", coordinates: { lat: 21.1602, lng: 76.9273 } }, // Akola
//   { pincode: "444502", coordinates: { lat: 21.1472, lng: 76.9494 } }, // Akola
//   { pincode: "441908", coordinates: { lat: 20.6107, lng: 78.1234 } }, // Pulgaon
//   { pincode: "441909", coordinates: { lat: 20.6347, lng: 78.1213 } }, // Pulgaon
//   { pincode: "441801", coordinates: { lat: 21.2199, lng: 79.6591 } }, // Pauni
//   { pincode: "441802", coordinates: { lat: 21.2303, lng: 79.6324 } }, // Pauni
//   { pincode: "442202", coordinates: { lat: 19.8889, lng: 79.8292 } }, // Rajura
//   { pincode: "442203", coordinates: { lat: 19.8669, lng: 79.8481 } }, // Rajura
//   { pincode: "441306", coordinates: { lat: 20.6672, lng: 78.8016 } }, // Hingna
//   { pincode: "441307", coordinates: { lat: 20.6544, lng: 78.7789 } }, // Hingna
//   { pincode: "445206", coordinates: { lat: 19.95, lng: 78.1133 } }, // Pandharkawda
//   { pincode: "445207", coordinates: { lat: 19.9301, lng: 78.1244 } }, // Pandharkawda
// ];
