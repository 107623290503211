import {
  Dropdown,
  Button,
  Select,
  Menu,
  Segmented,
  Input,
  Spin,
  Form,
  DatePicker,
  Typography,
  Card,
  Col,
  Row,
  Steps,
  InputNumber,
  Radio,
} from "antd";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";

import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const UpdateModal = ({
  open,
  handleClose,
  style,
  edit,
  form,
  onFinish,
  onFinishFailed,
}) => {
//   console.log(edit);

  const inputRef = useRef(null);
  const { Title } = Typography;
  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        ...edit,
        eventDate: moment(edit?.eventDate),
        endDate: moment(edit?.endDate),
        startDate: moment(edit?.startDate)
      });
    }
  }, [edit, open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Title level={4} style={{ display: "flex", justifyContent: "center" }}>
          Event Details
        </Title>
        <hr />
        <Form
          form={form}
          name="basic"
          className="custom-form"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          // onValuesChange={handleValuesChange}
        >
          <Form.Item
            style={{ marginBottom: "10px" }}
            name="eventName"
            label="Event Name"
            rules={[
              {
                required: true,
                message: "Enter event name!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter event name" />
          </Form.Item>
          <Form.Item
            name="eventDate"
            label="Event Date"
            rules={[{ required: true, message: "Please select a event date!" }]}
          >
            <DatePicker
              getPopupContainer={(trigger) => trigger.parentNode}
              size="large"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: "Enter event code!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter event code" />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            name="registrationCount"
            label="Registration Count"
            rules={[
              {
                required: true,
                message: "Enter event registration count!",
              },
            ]}
          >
            <InputNumber
              size="large"
              style={{ width: "100%" }}
              placeholder="Enter event registration count"
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            name="eventLimit"
            label="Registration Count Limit"
            rules={[
              {
                required: true,
                message: "Enter event registration count limit!",
              },
            ]}
          >
            <InputNumber
              size="large"
              style={{ width: "100%" }}
              placeholder="Enter event registration count limit"
            />
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Event Start Date"
            rules={[
              { required: true, message: "Please select a event start date!" },
            ]}
          >
            <DatePicker
              getPopupContainer={(trigger) => trigger.parentNode}
              size="large"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="Event End Date"
            rules={[
              { required: true, message: "Please select a event end date!" },
            ]}
          >
            <DatePicker
              getPopupContainer={(trigger) => trigger.parentNode}
              size="large"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="active"
            label="Status"
            rules={[{ required: true, message: "Please select a status!" }]}
          >
            <Radio.Group>
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Deactive</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className="modal-buttons"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button type="primary" htmlType="submit">
              {edit ? "Update" : "Submit"}
            </Button>
            &nbsp;
            {/* 
                        <Button type="primary" danger onClick={submitDelete}>
                            Delete
                        </Button> */}
            &nbsp;
            <Button onClick={handleClose}>Cancel</Button>
            {/* &nbsp;
                        {data?.active ?
                            <Button type="primary" danger onClick={() => handleUserStatus("Deactivate", false)}>Deactivate User</Button>
                            :
                            <Button type="primary" onClick={() => handleUserStatus("Activate", true)}>Activate User</Button>
                        }
                        &nbsp; */}
          </Form.Item>
          {/* <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="startYear"
                                label="Financial Start Year"
                                rules={[{ required: true, message: 'Please select a start year!' }]}
                            >
                                <DatePicker
                                    picker="year"
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    size="large"
                                    style={{ width: '100%' }}
                                // onChange={(date) => handleFYChange(date, 'startYear')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item

                                name="endYear"
                                label="Financial End Year"
                                rules={[
                                    { required: true, message: 'Please select an end year!' },
                                    {
                                        validator: (_, value) => {
                                            const startMonth = form.getFieldValue('startYear');
                                            if (startMonth && value) {
                                                if (value < startMonth) {
                                                    return Promise.reject(new Error('End year must be greater than start year!'));
                                                }
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >

                                <DatePicker picker="year" getPopupContainer={(trigger) => trigger.parentNode} size="large" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row> */}
        </Form>
      </Box>
    </Modal>
  );
};

export default UpdateModal;
